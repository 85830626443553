<template>
  <div class="applications-list">
    <b-row>
      <b-col cols="12" md="3" sm="6">
        <b-form-group label="Поиск по клиенту">
          <b-form-input
            v-model="search"
            debounce="500"
            placeholder="Поиск..."
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3" sm="6">
        <b-form-group label="Поиск по номерам">
          <b-form-input
            v-model="searchLicense"
            debounce="500"
            placeholder="Поиск по номерам..."
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3" sm="6">
        <b-form-group label="Поиск по комментариям">
          <b-form-input
            v-model="searchComment"
            debounce="500"
            placeholder="Поиск по комментариям..."
          />
        </b-form-group>
      </b-col>
      <b-col v-if="selectedStatus === 2" cols="12" md="3" sm="6">
        <b-form-group label="Поиск по причине отказа">
          <b-form-input
            v-model="searchDecline"
            debounce="500"
            placeholder="Поиск по причине отказа..."
          />
        </b-form-group>
      </b-col>
      <b-col class="text-right align-self-center">
        <b-button :to="{ name: 'application-create' }" variant="primary">
          Создать
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-form-group>
          <b-form-radio-group
            id="btn-radios-1"
            v-model="selectedStatus"
            :disabled="isLoading"
            :options="statusChoices"
            button-variant="outline-primary"
            buttons
            name="radios-btn-default"
            text-field="display_name"
            value-field="value"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-card>
          <b-overlay :show="isLoading" rounded="sm">
            <b-table
              :fields="fields"
              :items="applications"
              empty-text="Нет данных"
              hover
              responsive
              show-empty
              @row-clicked="openApplication"
            >
              <template
                v-for="field in fields"
                #[`cell(${field.key})`]="{ item }"
              >
                <router-link
                  :key="field.key"
                  :to="`/applications/${item.id}`"
                  style="color: #6e6b7b"
                >
                  {{ item[field.key] }}
                </router-link>
              </template>
              <template #cell(car)="data">
                {{ data.item.car_mark }} - {{ data.item.car_model }}
              </template>
              <template #cell(comments_details)="row">
                <b-button class="mr-2" size="sm" @click="row.toggleDetails">
                  {{ row.detailsShowing ? 'Спрятать' : 'Показать' }} комментарии
                </b-button>
              </template>

              <template #row-details="row">
                <b-table-lite
                  :fields="subFields"
                  :items="[row.item]"
                ></b-table-lite>
              </template>
            </b-table>
          </b-overlay>
          <b-row
            v-if="pageCount > 1"
            class="d-flex justify-content-center mt-2"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              aria-controls="my-table"
            ></b-pagination>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'ApplicationsList',
  data() {
    return {
      applications: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'created',
          label: 'Дата заявки',
          formatter: (value) => {
            return `${DateTime.fromISO(value).toFormat('dd.LL.yyyy HH:mm')}`
          },
        },
        {
          key: 'phone',
          label: 'Телефон',
        },
        {
          key: 'name',
          label: 'Имя',
        },
        {
          key: 'preferable_amount',
          label: 'Сумма',
        },
        {
          key: 'duration_months',
          label: 'Срок, мес.',
        },
        {
          key: 'car',
          label: 'Авто',
        },
        {
          key: 'comments_details',
          label: 'Комментарии',
        },
      ],
      subFields: [
        {
          key: 'comment',
          label: 'Комментарий',
        },
      ],
      selectedStatus: 1,
      statusChoices: [],
      search: '',
      searchDecline: '',
      searchComment: '',
      searchLicense: '',
      currentPage: 1,
      totalRows: 0,
      perPage: 50,
      isLoading: false,
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalRows / this.perPage)
    },
  },
  watch: {
    selectedStatus() {
      this.currentPage = 1
      this.getApplications()
      if (this.selectedStatus === 2) {
        this.subFields.push({
          key: 'decline_comment',
          label: 'Причина отказа',
        })
      } else {
        if (this.subFields.length === 2) {
          this.subFields.pop()
        }
      }
    },
    search() {
      this.currentPage = 1
      this.getApplications()
    },
    searchLicense() {
      this.currentPage = 1
      this.getApplications()
    },
    searchComment() {
      this.currentPage = 1
      this.getApplications()
    },
    searchDecline() {
      this.currentPage = 1
      this.getApplications()
    },
    currentPage() {
      this.getApplications()
    },
  },
  created() {
    this.getApplications()
    this.getApplicationOptions()
  },
  methods: {
    async getApplications() {
      this.isLoading = true
      const res = await this.$http.get(`admin/applications/`, {
        params: {
          page: this.currentPage,
          status: this.selectedStatus,
          search: this.search,
          comment__icontains: this.searchComment,
          decline_comment__icontains: this.searchDecline,
          license_plate__icontains: this.searchLicense,
        },
      })
      this.applications = res.data.results
      this.totalRows = res.data.count
      this.isLoading = false
    },
    async getApplicationOptions() {
      const res = await this.$http.options('admin/applications/')
      this.statusChoices = res.data.actions.POST.status.choices
    },
    openApplication(item) {
      this.$router.push({
        name: 'application-view',
        params: { appId: item.id },
      })
    },
  },
}
</script>
